<template>
  <section class="w-100 position-relative p-1">
    <div class="box-style-analytics mb-2">
      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center">
          <b-avatar :src="data_user.basic.avatar_url" size="40px" class="mr-1">
          </b-avatar>
          <b-avatar class="container-avatar" variant="light" size="20">
            <b-img
              :src="getIconWhite('twitch')"
              :class="getIconColor('twitch', false)"
              fluid
            />
          </b-avatar>
          <p class="f-12 m-0">
            <span
              class="text-link text-underline cursor-pointer"
              @click="redirectToSocialNet(network, data_user.basic.username)"
            >
              @{{ data_user.basic.username }}
            </span>
          </p>
        </div>

        <div class="d-flex align-items-center">
          <div>
            <p class="text-muted-left-side-analytics">
              {{ getFormat(data_user.metrics.subscribers_count.value) }}
              {{ $t("campaigns.followers") }}
            </p>
          </div>
          <b-button
            variant="outline-secondary-custom-analysis"
            @click="redirectToSocialNet(network, data_user.basic.username)"
            class="outline-secondary-custom-analysis ml-2"
          >
            <feather-icon icon="ExternalLinkIcon"></feather-icon>
          </b-button>
        </div>
      </div>
      <p class="mt-2 text-muted-left-side-analytics">
        <span :class="{ 'collapsed-text': !showMore }">
          {{ data_user.basic.description }}
        </span>
        <span
          v-if="data_user.basic.description.length > 100"
          class="text-primary cursor-pointer"
          @click="toggleDescription"
        >
          {{ showMore ? $t("instagram.seeLess") : $t("instagram.seeMore") }}
        </span>
      </p>
    </div>

    <div>
      <vue-horizontal
        class="horizontal"
        :displacement="0.3"
        snap="center"
      >
        <b-col v-for="(item, index) in data_user.features.last_media.data" :key="index" class="d-flex">
            <b-card no-body class="size-card-animation">
                <div class="preview-container">
                    <img
                    :src="item.basic.animated_preview_url"
                    alt="Preview"
                    class="preview-image"
                    />
                </div>

                <b-card-text class="p-2">
                    <span class="text-title-overflow">
                    {{ item.basic.title }}
                    </span>
                    <p class="mt-1 text-muted-left-side-analytics">
                    <b-icon icon="eye" />
                    {{ getFormat(item.metrics.views_count) }}
                    </p>
                </b-card-text>
            </b-card>
        </b-col>
      </vue-horizontal>
    </div>

    <analysis-account  
      :is_paid="is_paid"
      :data_user="data_user"
      :network="network"
      @get_profile_url="getProfileUrl"
    />

  </section>
</template>
  
<script>
import { getFormat } from "@/libs/utils/formats";
import { redirectToSocialNet } from "@/libs/utils/urls";
import { getIconColor, getIconWhite } from "@/libs/utils/icons";
import { BButton, BAvatar, BImg, BIcon, BCard, BCol, BCardText } from "bootstrap-vue";
import VueHorizontal from "vue-horizontal";

export default {
  components: {
    BButton,
    BAvatar,
    BImg,
    BIcon,
    VueHorizontal,
    BCard,
    BCol,
    BCardText,
    AnalysisAccount: () => import("@/views/components/sidebar/twitch/AnalysisAccount.vue"),
  },
  props: ["is_paid", "data_user", "network"],
  data() {
    return {
      showMore: false,
      getIconColor,
      getIconWhite,
      getFormat,
      redirectToSocialNet,
      more_info: [
        this.$t("search_preview.info_twitch.liveViewsAvg"),
        this.$t("search_preview.info_twitch.audienceCountries"),
        this.$t("search_preview.info_twitch.ageAndGenderAudience"),
        this.$t("search_preview.info_twitch.transmissions7Days"),
        this.$t("search_preview.info_twitch.chatMessagesHour"),
        this.$t("search_preview.info_twitch.more8Metrics"),
      ],
    };
  },
  methods: {
    getProfileUrl() {
      this.$emit('get_profile_url');
    },
    toggleDescription() {
      this.showMore = !this.showMore;
    },
  },
};
</script>

<style lang="scss" scoped>
.text-title-overflow {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: normal;
}
.collapsed-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
.box-style-analytics {
  border-radius: 6px;
  box-shadow: 0 1px 4px #0b14260a;
  padding: 16px;
  background-color: white;
  border: 1px solid #e2e8f0;
  .grid-more-info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4);
    gap: 10px;
  }
}
.outline-secondary-custom-analysis {
  background: white;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 4px;
  padding: 7px;
}
.text-link:hover {
  color: #7367f0;
}
.text-muted-left-side-analytics {
  font-size: 14px;
  font-family: "avenir-medium";
  color: #718096;
  margin-bottom: 0.3em;
}

.size-card-animation {
  width: 250px;
  overflow: hidden;
  margin-right: 10px;
}

.preview-container {
  width: 100%;
  height: 140px;
  overflow: hidden;
  position: relative;
}

.preview-image {
  width: 100%;
  height: auto;
  display: block;
  object-fit: cover;
}

.preview-container:hover .preview-image {
  animation: frameScroll 2s steps(10);
}

@keyframes frameScroll {
  0% { transform: translateY(0%); }
  100% { transform: translateY(-100%); }
}

</style>